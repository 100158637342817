<template>
  <v-form lazy-validation v-model="valid" ref="form">
    <v-row>
      <v-col cols="12" sm="12" md="11" lg="11">
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Kasbon # <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-autocomplete
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="kasbon"
                  item-text="kasbonNumber"
                  :items="listDriverKasbon"
                  return-object
                  @change="onChangeKasbon"
                  :rules="[v => !!v || 'Kasbon is required']"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Name</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <span>{{ form.name }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Date</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-menu
                  v-model="menuDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      flat
                      dense
                      background-color="white"
                      v-model="form.date"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="form.date" @input="menuDate = false"></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Payment</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <span>{{ form.paymentMethod }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6" v-if="form.paymentMethod === 'Transfer'">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Account Number</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <span>{{ accountNumber(form.accountNumberId) }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Division</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <span>{{ form.division }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Currency</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <span>{{ form.currency }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Remark</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-textarea
                  outlined
                  flat
                  rows="3"
                  background-color="white"
                  v-model="form.remark"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-tabs vertical>
              <v-tab background-color="light-blue accent-3" dark class="px-0">
                <v-icon>
                  mdi-note-text
                </v-icon>
              </v-tab>
              <v-tab-item>
                <v-card flat class="px-2">
                  <v-row>
                    <v-col cols="6"> </v-col>
                    <v-col cols="6" align-self="start" class="text-right pb-0">
                      <p class="headline font-italic">
                        Requirement <span class="red--text">*</span>
                      </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-data-table
                        :headers="headers"
                        :items="items"
                        disable-pagination
                        hide-default-footer
                        @click:row="onClickRow"
                      >
                        <template v-slot:item.no="{ item }">
                          {{ items.map(x => x).indexOf(item) + 1 }}
                        </template>
                        <template v-slot:item.amount="{ item }">
                          {{ formatPrice(item.amount) }}
                        </template>
                        <template v-slot:item.usedAmount="{ item }">
                          {{ formatPrice(item.usedAmount) }}
                        </template>
                        <template v-slot:item.remainAmount="{ item }">
                          {{ formatPrice(item.remainAmount) }}
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="2">
            <v-card outlined>
              <v-card-title class="py-0">Total Kasbon</v-card-title>
              <v-card-text class="text-right mt-3">
                <span>{{ formatPrice(totalAmount) }}</span>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="2">
            <v-card outlined>
              <v-card-title class="py-0">Total Used</v-card-title>
              <v-card-text class="text-right mt-3">
                <!-- <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model.number="form.totalUseAmount"
                  @input="onInputTotalUseAmount"
                ></v-text-field> -->
                <span>{{ formatPrice(totalUseAmount) }}</span>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="2">
            <v-card outlined>
              <v-card-title class="py-0">Remain Kasbon</v-card-title>
              <v-card-text class="text-right mt-3">
                <span>{{ formatPrice(totalRemainAmount) }}</span>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="12" md="1" lg="1" class="py-0">
        <v-row class="py-0">
          <v-col cols="12" class="mb-4 py-0">
            <v-btn color="primary" @click="submit">
              <v-icon large>
                mdi-content-save
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <dialog-kasbon
      :item="item"
      :index="index"
      :dialog="dialog"
      @close="close"
      @save="addItem"
      @deleteItem="deleteItem"
      type="settlement"
    ></dialog-kasbon>
  </v-form>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import DialogKasbon from "@/components/DialogKasbon";

export default {
  name: "create-settlement-driver-kasbon",
  components: {
    "dialog-kasbon": DialogKasbon,
  },
  data: () => ({
    defaultForm: {
      formNumber: "",
      date: moment().format("yyyy-MM-DD"),
      employeeId: 0,
      name: "",
      division: "",
      paymentMethod: "",
      remark: "",
      currency: null,
      accountNumberId: null,
      totalAmount: 0,
      totalUseAmount: 0,
      totalRemainAmount: 0,
      driverKasbonId: null,
      items: [],
      status: "Outstanding",
    },
    menuDate: false,
    form: {},
    items: [],
    dialog: false,
    item: {},
    index: -1,
    category: null,
    listCategory: [],
    kasbon: null,
    listDriverKasbon: [],
    listPaymentMethod: ["Cash", "Transfer"],
    valid: true,
    headers: [
      {
        text: "No",
        value: "no",
        sortable: false,
        divider: true,
      },
      {
        text: "Requirement",
        value: "category",
        sortable: false,
        divider: true,
      },
      {
        text: "Description",
        value: "description",
        sortable: false,
        divider: true,
      },
      {
        text: "Amount",
        value: "amount",
        sortable: false,
        divider: true,
      },
      {
        text: "Used Amount",
        value: "usedAmount",
        sortable: false,
        divider: true,
      },
      {
        text: "Remain Amount",
        value: "remainAmount",
        sortable: false,
        divider: true,
      },
    ],
  }),

  computed: {
    totalAmount() {
      let total = 0;
      this.items.map(x => {
        total = total + x.amount;
      });
      return total;
    },
    totalUseAmount() {
      let total = 0;
      this.items.map(x => {
        total = total + x.usedAmount;
      });
      return total;
    },
    totalRemainAmount() {
      let total = 0;
      this.items.map(x => {
        total = total + x.remainAmount;
      });
      return total;
    },

    ...mapState("generalKasbon", ["listAccountNumber"]),
    ...mapState("settlementDriver", ["lastFormNumber"]),
  },

  watch: {},

  methods: {
    async submit() {
      if (this.$refs.form.validate() && this.items.length > 0) {
        this.form.formNumber = this.lastFormNumber;
        this.form.totalAmount = this.totalAmount;
        this.form.totalUseAmount = this.totalUseAmount;
        this.form.totalRemainAmount = this.totalRemainAmount;
        this.form.items = this.items;

        this.$store.commit("SET_LOADER", true);
        await this.$store
          .dispatch("settlementDriver/create", this.form)
          .then(() => this.$store.commit("SET_LOADER", false))
          .catch(() => {
            this.$store.dispatch("toast", {
              type: "error",
              message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
            });
            this.$store.commit("SET_LOADER", false);
          });
        this.clear();
      } else {
        this.$store.dispatch("toast", {
          type: "error",
          message: "Requirement is required",
        });
      }
    },
    deleteItem(index) {
      if (index > -1) {
        this.items.splice(index, 1);
        this.dialog = false;
      }
    },
    addItem(item) {
      this.items[this.index] = item;
    },
    close() {
      this.dialog = false;
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    clear() {
      this.items = [];
      this.form = Object.assign({}, this.defaultForm);
    },
    async onChangeKasbon(val) {
      this.form.driverKasbonId = val.id;
      this.items = val.items;
      this.form.division = val.division;
      this.form.currency = val.currency;
      this.form.employeeId = val.employeeId;
      this.form.name = val.kasbonName;
      this.form.paymentMethod = val.paymentMethod;
      this.form.accountNumberId = val.accountNumberId;
      await this.$store.dispatch("generalKasbon/getListAccountNumber", val.employeeId);
    },
    onClickRow(item) {
      this.item = item;
      this.index = this.items.map(x => x).indexOf(item);
      this.dialog = true;
    },
    addCategory(item) {
      this.item = item;
      this.index = this.items.map(x => x).indexOf(item);
      this.dialog = true;
      this.items.push(item);
    },
    onInputTotalUseAmount() {
      this.form.totalRemainAmount = this.totalAmount - this.form.totalUseAmount;
    },
    async getListCategory() {
      const response = await this.$store.dispatch("generalKasbon/getListCategory");
      if (response.status === 200) {
        this.listCategory = response.data;
      }
    },
    async getListDriverKasbon() {
      const response = await this.$store.dispatch("driverKasbon/getListDriverKasbonForSettlement");
      if (response.status === 200) {
        this.listDriverKasbon = response.data;
      }
    },
    accountNumber(id) {
      const number = this.listAccountNumber.find(x => x.id === id);
      const result = number ? number.accountNumber : "";
      return result;
    },
    async getInitialData() {
      this.$store.commit("SET_LOADER", true);
      Promise.all([
        await this.$store.dispatch("settlementDriver/getLastFormNumber"),
        this.getListCategory(),
        this.getListDriverKasbon(),
      ])
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
  },

  mounted() {
    this.getInitialData();
    this.form = Object.assign({}, this.defaultForm);
  },
};
</script>

<style></style>
